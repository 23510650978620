import styled from 'styled-components'

const Styling = styled.div`
display: flex;
flex-wrap: wrap;
justify-content: center;
padding-bottom:4rem;

    @media only screen and (min-width: 1024px) {            
    padding-bottom:8rem;
    }

    .products{
        @media only screen and (min-width: 1024px) {
            padding-bottom:4rem;
        }
    }

`

export { Styling }