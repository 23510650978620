import styled from 'styled-components'

const Styling = styled.div`
width: 100%;
position: relative;
display: flex;

    .gatsby-image-wrapper{
    width: 100%;
    height:100%;

        &:before{
        width:100%;
        height:100%;
        position: absolute;
        top:0;
        left:0;
        content:"";
        background:rgba(0,0,0,.6);
        z-index: 10;
        }

    }

    .hero{
    width:100%;
    min-height:50vh;
    overflow: hidden;

        div{
        height:100%;
        }

    }

    .parallax-outer{
    width: 100%;
    height:100%;
    overflow: hidden;

        .parallax-inner{
        height: 100%;
        }

    }

    .main_title{
    position: absolute;
    z-index: 100;
    color:var(--white);
    text-transform:uppercase;
    ${({theme}) => theme.tomato('regular')}  
    display: flex;
    align-items: center;
    font-size:2.5rem;
    line-height:2rem;
    bottom:2rem;
    left:2rem;
        
        @media only screen and (min-width: 350px) {
        font-size:3rem;
        line-height:2.6rem;
        letter-spacing: -0.2rem;
        bottom:4rem;
        left:4rem;
        }

        @media only screen and (min-width: 420px) {
        font-size:4rem;
        line-height:3rem;
        letter-spacing: -0.3rem;
        }
        
        @media only screen and (min-width: 610px) {
        font-size:5rem;
        line-height:4rem;
        letter-spacing: -0.4rem;
        }
        
        @media only screen and (min-width: 800px) {
        /* margin-bottom:clamp(2rem, 4vw, 8rem); */
        font-size: clamp(6rem,6vw,8rem);
        line-height: clamp(6rem/1.15,6vw/1.15,8rem/1.15);
        letter-spacing: -0.7rem;
        bottom:8rem;
        left:8rem;
        }


        svg{
        margin-right:0.2em;
        width:0.7em;
        height:0.7em;
        }

    }

`

export { Styling }