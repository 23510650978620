import styled from 'styled-components'

const Styling = styled.div`
background:var(--white);

    .product_slider{
    margin-top:4rem;
    
        @media only screen and (min-width: 1024px) {
        margin-top:8rem;
        }

        .swiper-container{
        padding-bottom:0;
        }

        .viewAll{
            display:none;
        }

    }

    .products{
    width:100%;
    position: relative;
    display:grid;
    grid-gap:2rem;
    padding:0 2rem;
    grid-template-columns: repeat(1, 1fr);

        @media only screen and (min-width: 350px) {
        padding:0 4rem;
        }

        @media only screen and (min-width: 550px) {
        grid-template-columns: repeat(2, 1fr);
        }

        @media only screen and (min-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
        }

        @media only screen and (min-width: 1024px) {
        padding:0 8rem;
        }

        @media only screen and (min-width: 1200px) {
        grid-template-columns: repeat(4, 1fr);
        }

        &.noFilters{
        padding:2rem 2rem 0 2rem;

            @media only screen and (min-width: 350px) {
            padding:4rem 4rem 0 4rem;
            }

            @media only screen and (min-width: 550px) {
            grid-template-columns: repeat(2, 1fr);
            }

            @media only screen and (min-width: 768px) {
            grid-template-columns: repeat(3, 1fr);
            }

            @media only screen and (min-width: 1024px) {
            padding:8rem 8rem 0 8rem;
            }

            @media only screen and (min-width: 1200px) {
            grid-template-columns: repeat(4, 1fr);
            }

        }

        article{
        position: relative;
        }
    
    }

`

export { Styling }