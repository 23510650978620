import React from "react";
import { Styling } from "./styled.js";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

export default function ProductCard({ product, smallText }) {
  let image;
  let text;
  let title;
  let base;
  let slug;

  if (smallText !== undefined) {
    text = smallText;
  }

  if (product?.acf_product_info?.merch !== undefined) {
    image = product?.acf_product_info?.merch?.images[0]?.image?.localFile;
    title = product.title;
    slug = product.slug;
    text = "£" + product.acf_product_info.merch.price;
    base = "/shop/merch/";
  }
  if (product?.product?.acf_product_info?.samplePacks !== undefined) {
    image =
      product?.product?.acf_product_info?.samplePacks?.images[0]?.image
        ?.localFile;
    title = product?.product?.title;
    slug = product?.product?.slug;
    base = "/shop/sample-packs/";
  }
  if (product?.acf_product_info?.samplePacks !== undefined) {
    image = product?.acf_product_info?.samplePacks?.images[0]?.image?.localFile;
    title = product.title;
    slug = product.slug;
    text = "£" + product.acf_product_info.samplePacks.price;
    base = "/shop/sample-packs/";
  }
  if (product?.acf_product_info?.merch !== undefined) {
    image = product?.acf_product_info?.merch?.images[0]?.image?.localFile;
    title = product.title;
    slug = product.slug;
    text = "£" + product.acf_product_info.merch.price;
    base = "/shop/merch/";
  }
  if (product?.courseBuilder?.courseThumbnailImageSquare !== undefined) {
    image = product?.courseBuilder?.courseThumbnailImageSquare?.localFile;
    title = product.title;
    slug = product.slug;
    text = "£" + product.courseBuilder.price;
    base = "/programmes/on-demand/";
  }

  if (product?.hero?.blogHero?.image !== undefined) {
    image = product?.hero?.blogHero?.image?.localFile;
    title = product.title;
    slug = product.slug;
    text = "";
    base = "/features/";
  }

  // //console.log(product)

  const theImage = getImage(image);

  return (
    <Styling>
      <Link to={base + slug}>
        {/* <Link to={product?.product?.link || product.link || '/programmes/on-demand/'+product.slug}> */}
        <GatsbyImage image={theImage} className="image" alt={"test"} />
        <div className="info">
          <div className="title">{title}</div>
          <div className="smallText">{text}</div>
        </div>
      </Link>
    </Styling>
  );
}
