import React, {useEffect} from 'react'
import { Styling } from './styled.js'
import {GatsbyImage,  getImage} from 'gatsby-plugin-image';
import ArrowRight from '../../../images/arrow_down_right.inline.svg'
import { RellaxWrapper } from 'react-rellax-wrapper'

export default function HeroImageTitle({hero, title}) {
    const theImage = getImage(hero);


    return (
        
        <Styling>
            <h1 className="main_title"><ArrowRight /> {title}</h1>
            <div className="hero">
                <RellaxWrapper speed={-9} >
                    <GatsbyImage image={theImage} className="image" alt={'test'} />
                </RellaxWrapper>
            </div>
        </Styling>
    )
}
