import React, { useState, useEffect } from "react";
import ProductCard from "../../../components/reusable/ProductCard";
import { Styling } from "./styled";

export default function ProductsGridViewMore({ title, products, noFilters }) {
  // Array of all products
  const allProducts = products;

  //console.log(allProducts)

  // State for the list
  const [list, setList] = useState([...allProducts.slice(0, 12)]);

  // State to trigger oad more
  const [loadMore, setLoadMore] = useState(false);

  // State of whether there is more to load
  const [hasMore, setHasMore] = useState(allProducts.length > 12);

  // Load more button click
  const handleLoadMore = () => {
    setLoadMore(true);
  };

  // Handle loading more articles
  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length;
      const isMore = currentLength < allProducts.length;
      const nextResults = isMore
        ? allProducts.slice(currentLength, currentLength + 12)
        : [];
      setList([...list, ...nextResults]);
      setLoadMore(false);
    }
  }, [loadMore, hasMore]); //eslint-disable-line

  //Check if there is more
  useEffect(() => {
    const isMore = list.length < allProducts.length;
    setHasMore(isMore);
  }, [list]); //eslint-disable-line

  return (
    <Styling>
      <div className={`products ${noFilters === true ? "noFilters" : ""}`}>
        {list.map((product, index) => {
          return (
            <ProductCard
              key={`product_${index}`}
              product={product}
              smallText={product?.product?.productCategories?.nodes[0].name}
            />
          );
        })}
      </div>
      {hasMore ? (
        <button className="capsule_button white" onClick={handleLoadMore}>
          Load More
        </button>
      ) : (
        ""
      )}
    </Styling>
  );
}
