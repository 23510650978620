import React from 'react'
import { Styling } from './styled.js'
import { navigate } from 'gatsby'
import {BiChevronDown} from 'react-icons/bi'

export default function CategoryFilters({filters, base, current}) {
    
    const handleChange = (e) => {
        navigate(e.target.value) 
    }

    return (
        <Styling>
            <label htmlFor="shop-filter">Pick a category</label>
            <div className="filter-wrap">
                <select name="shop-filter" id="shop-filter" onChange={(e) => handleChange(e)}>
                    <option value={`/shop/${base}/`} selected={current === undefined ? 'selected' : ""}>All</option>
                    {filters.map((filter, index) =>
                        <option
                            key={`${base}_filters_${index}`}
                            value={`/shop/${base}/${filter.slug}`}
                            selected={current === filter.name ? 'selected' : ''}>
                                {filter.name}
                        </option>
                    )}
                </select>
                <BiChevronDown />
            </div>
        </Styling>
    )
}
