import styled from 'styled-components'

const Styling = styled.div`
width:100%;
padding:4rem 2rem;
background:var(--white);
display: flex;
justify-content: flex-start;
flex-wrap: wrap;

    @media only screen and (min-width: 350px) {
    padding:4rem;
    }

    @media only screen and (min-width: 1024px) {
    padding:4rem 8rem;
    }

    label{
    width: 100%;
    margin-bottom: 1rem;
    text-align: left;
    font-family: 'telegraf--regular';
    text-transform: uppercase;        
    }

    select{
    border:1px solid var(--black);
    padding:1rem;
    -webkit-appearance:none;
    width:100%;
    background:var(--white);
    border-radius: 0;
        
        @media only screen and (min-width: 550px) {
        width:auto;
        }

        &:focus{
        outline:none;
        }

    }

    .filter-wrap{
    position: relative;
    width:100%;

        @media only screen and (min-width: 550px) {
        width:auto;
        }

        svg{
        position: absolute;
        right:0.5rem;
        top:50%;
        transform:translateY(-50%);
        pointer-events: none;
        }
    }
    

`

export { Styling }