import React from "react"
import { graphql } from "gatsby"
import {Styling} from '../styles/pages/shop-category.styled'
import HeroImageTitle from '../components/reusable/HeroImageTitle'
import CategoryFilters from '../components/reusable/CategoryFilters'
import ProductsGridViewMore from '../components/reusable/ProductsGridViewMore'
import Seo from '../components/global/seo'

const SamplePacks = ({data, pageContext}) => {
  
  return (
    <Styling>
      <Seo title={`${pageContext.name} | ${data.wpPage.seo.title}`} description={data.wpPage.seo.metaDesc} />
      <HeroImageTitle title={pageContext.name} hero={data?.wpPage?.acf_hero?.heroImage?.localFile} />
      <CategoryFilters 
      current={pageContext.name}
      filters={
        pageContext.base === 'sample-packs' ? data.filtersSamplePacks.nodes
        : data.filtersMerch.nodes
      } 
      base={pageContext.base}
       />
      <ProductsGridViewMore 
        title={pageContext.baseTitle}
        products={ 
        pageContext.base === 'sample-packs' ? data.productsSamplePacks.nodes
        : data.productsMerch.nodes
        } />
    </Styling>
  )
}

export default SamplePacks

export const query = graphql`
  query($baseTitle: String!, $name: String!){
    wpPage(title: {eq: $baseTitle}) {
      id
      seo{
        title
        metaDesc
      }
      acf_hero {
        heroImage {
          localFile{
            childImageSharp {
              gatsbyImageData( 
                width: 800
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
    filtersSamplePacks: allWpSamplePackCategory {
      nodes {
        slug
        name
      }
    }
    filtersMerch: allWpMerchCategory {
      nodes {
        slug
        name
      }
    }
    productsSamplePacks: allWpProduct(
      filter: {productCategories: {nodes: {elemMatch: {name: {eq: $baseTitle}}}}, samplePackCategories: {nodes: {elemMatch: {name: {eq: $name}}}}}
    ) {
      nodes {
        id
        title
        slug
        acf_product_info {
          samplePacks {
            price
            images {
              image {
                localFile{
                  childImageSharp {
                    gatsbyImageData( 
                      width: 800
                      placeholder: BLURRED
                      formats: [AUTO, WEBP]
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
    productsMerch: allWpProduct(
      filter: {productCategories: {nodes: {elemMatch: {name: {eq: $baseTitle}}}}, merchCategories: {nodes: {elemMatch: {name: {eq: $name}}}}}
    ) {
      nodes {
        id
        title
        slug
        acf_product_info {
          merch {
            price
            images {
              image {
                localFile{
                  childImageSharp {
                    gatsbyImageData( 
                      width: 800
                      placeholder: BLURRED
                      formats: [AUTO, WEBP]
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`