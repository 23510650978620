
import styled from 'styled-components'

const Styling = styled.article`
width:100%;
height:100%;
display: flex;
flex-wrap: wrap;
position: relative;


    a{
    text-decoration: none;
    width:100%;
    }

    .image-wrapper{
    width: 100%;
    height:100%;
    position: relative;
    margin-bottom: 4rem;
    }

    .gatsby-image-wrapper{
    width: 100%;
    position: relative;
    border-radius: 0.5rem;
    overflow: hidden;
    height: 0;
    padding-bottom: 100%;

        picture{
        position: absolute;
        width: 100%;
        padding-bottom: 100%;
        top: 0;
        left: 0;
        height:0;
        }

        img{
        ${({theme}) => theme.easing('0.5')}  
        }

    }

    .info{
        position: relative;
    /* position: absolute;
    top:100%; */
    padding:2rem 0;

        .title{
        position: relative;
        ${({theme}) => theme.tomato('regular')}  
        text-transform:uppercase;
        /* letter-spacing: -0.4rem; */
        font-size:1.8rem;
        line-height:2.4rem;
        margin-bottom: 1rem;

            @media only screen and (min-width: 1024px) {
            font-size:clamp(2rem, 1vw, 6rem);
            }

        }

    }

    a{
    color:inherit;

        &:hover{
            .gatsby-image-wrapper{
                img{
                transform:scale(1.1);
                }
            }
        }

    }
  

        


`

export { Styling }